<template>
  <div class="container py-4 mt-5 rounded">
    <h2 class="heading-section text-center">Contact</h2>
    <div class="contact-wrapper px-4">
      <div class="row mt-3">
        <div class="col-md-12 mt-2">
          <form id="contactForm" v-on:submit.prevent="handleSubmit()">
            <div class="mb-3">
              <label class="form-label" for="name">Naam</label>
              <input class="form-control" v-model="formParams.name" id="name" type="text" placeholder="Name" required />
            </div>
            <div class="mb-3">
              <label class="form-label" for="email">Email</label>
              <input class="form-control" id="email" v-model="formParams.email" type="email" placeholder="Email" required />
            </div>
            <div class="mb-3">
              <label class="form-label" for="tel">Telefoonnummer</label>
              <input class="form-control" id="tel" v-model="formParams.tel" type="tel" placeholder="Telefoonnummer" />
            </div>
            <div class="mb-3">
              <label class="form-label" for="message">Bericht</label>
              <textarea
                class="form-control"
                id="message"
                v-model="formParams.message"
                type="text"
                placeholder="Uw vraag of opmerking..."
                style="height: 10rem"
                required
              ></textarea>
            </div>
            <div class="d-grid">
              <button class="btn btn-primary btn-lg mb-5" type="submit">Verzenden</button>
            </div>
          </form>
          <div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d19847.34047925642!2d3.5821130170102577!3d51.5514106824976!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c49411fd1f55e7%3A0xf03030b5c7d0e498!2sSerooskerke!5e0!3m2!1snl!2snl!4v1648150571185!5m2!1snl!2snl"
              width="100%"
              height="100%"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
        <div class="container">
          <div class="row mt-3 align-items-center justify-content-center">
            <div class="col-md-3">
              <div class="dbox w-100 text-center">
                <div class="icon d-flex align-items-center justify-content-center">
                  <span class="fa fa-phone fa-3x"></span>
                </div>
                <div class="text">
                  <p>
                    <a href="tel://1234567920">+31 (0) 6 40288476</a>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="dbox w-100 text-center">
                <div class="icon d-flex align-items-center justify-content-center">
                  <span class="fa fa-globe fa-3x"></span>
                </div>
                <div class="text">
                  <p>
                    <a
                      target="_blank"
                      href="https://www.airbnb.nl/rooms/44134099?source_impression_id=p3_1647972780_UoD2CWLTBfFPeNej"
                      >airbnb.nl</a
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row justify-content-center">
      <hr class="featurette-divider col-md-10 mx-auto mt-5" />
      <h2 class="heading-section text-center">Beschikbaarheid</h2>
      <div class="col-md-8 text-center mt-5">
        <iframe
          src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=Europe%2FAmsterdam&showTitle=0&showNav=1&showDate=1&showPrint=0&showTabs=1&showCalendars=0&showTz=0&title&src=NDQ3ZmE5bGFzMTRlODVtajdxbG12dGdob3I0bzV0MnBAaW1wb3J0LmNhbGVuZGFyLmdvb2dsZS5jb20&color=%237986CB"
          style="border-width: 0"
          width="100%"
          height="500"
          frameborder="0"
          scrolling="no"
        ></iframe>
      </div>
    </div>
  </div>
</template>
<script>
import emailjs from "emailjs-com";
export default {
  name: "Contact",
  data() {
    return {
      formParams: {
        name: "",
        email: "",
        tel: "",
        message: "",
      },
    };
  },
  methods: {
    handleSubmit() {
      var template_params = this.getEmailParams();
      this.sendMail(template_params);
    },
    getEmailParams() {
      return {
        from_name: this.formParams.name,
        email: this.formParams.email,
        tel: this.formParams.tel,
        message: this.formParams.message,
      };
    },
    sendMail(template_params) {
      emailjs.send("service_guto1wi", "template_ybm869c", template_params, "whDoEYRuaTHVS4DnC").then(
        () => {
          this.$swal.fire("Bedankt voor uw bericht", "Wij nemen z.s.m. contact met u op", "success");
        },
        (error) => {
          console.log("FAILED", error);
        }
      );
    },
  },
};
</script>
<style scoped>
.contact-wrapper {
  background-color: rgb(243, 243, 243);
}
</style>
